import * as React from 'react';
import ContactUsForm from '../components/contact-us-form/contact-us-form';

const ContactUs = () => {
    return (
        <div className='p-4r font-s3r'>
            <ContactUsForm></ContactUsForm>
        </div>
    )
};

export default Object.assign(ContactUs, {pageTitle: 'Contact Us'});